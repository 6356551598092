import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/FullWidthWithImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import MainFeature3 from "components/features/TwoColWithButton.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import SliderCard from "components/cards/ThreeColSlider.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"

export default () => (
  <AnimationRevealPage>
    <Hero />
    <MainFeature 
      subheading={'Đôi nét về TPTech'}
      heading={(
        <>
          Công ty TNHH Thương Mại và Dịch Vụ <wbr /> <span tw="text-primary-500">TP Tech</span>
        </>
      )}
      description={'Được thành lập từ những ngày đầu của năm 2018. Chúng tôi hiểu rõ tầm quan trọng của chất lượng và hiệu suất động cơ trong việc hoạt động của một phương tiện. Đó là lý do tại sao chúng tôi cam kết đem đến các giải pháp sửa chữa chuyên nghiệp, đảm bảo khôi phục và nâng cao hiệu suất của động cơ tối ưu nhất.'}
      />
    <SliderCard 
      heading={'Hoạt động nổi bật'}/>
    <Blog 
      subheading={''}
      heading={
        <>
          Lĩnh vực hoạt động
        </>
      }
      description={'Hoạt động nổi bật ở 3 lĩnh vực chính'}
      />
       <MainFeature3 
        subheading=""
        heading="Sửa chữa và bảo trì động cơ"
        imageSrc={'https://images.pexels.com/photos/4116225/pexels-photo-4116225.jpeg?auto=compress&cs=tinysrgb&w=800'}
        description={'Chúng tôi là một công ty hàng đầu trong lĩnh vực sửa chữa động cơ, cam kết mang đến cho bạn giải pháp tối ưu và chất lượng cao nhất cho mọi nhu cầu của bạn. Với đội ngũ kỹ thuật viên giàu kinh nghiệm và chuyên môn, chúng tôi tự tin rằng sẽ khắc phục mọi vấn đề và đem lại hiệu suất tối đa cho động cơ của bạn.'}
        primaryButtonText={'Liên hệ ngay'}
        buttonRounded={false}
        textOnLeft={false}
      />
       <MainFeature3 
        subheading=""
        heading="Cung cấp phụ tùng chính hãng"
        imageSrc={'https://aloauto.net/wp-content/uploads/2021/11/phu-tung-o-to-6.jpg'}
        description={'Với đội ngũ nhân viên giàu kinh nghiệm và am hiểu sâu về các loại phụ tùng và các thương hiệu hàng đầu, chúng tôi sẵn lòng tư vấn và hỗ trợ khách hàng trong việc lựa chọn phụ tùng phù hợp với nhu cầu của họ. Bất kể bạn đang cần phụ tùng cho ô tô, xe máy hay các loại phương tiện khác, chúng tôi sẽ đáp ứng mọi yêu cầu của bạn với một sự đa dạng về sản phẩm và mức giá cạnh tranh.'}
        primaryButtonText={'Liên hệ ngay'}
        buttonRounded={false}
        textOnLeft={false}
      />
       <MainFeature3 
        subheading=""
        heading="Vệ sinh công nghiệp"
        imageSrc={'https://vesinhcongnghiep.top/wp-content/uploads/2020/09/ve-sinh-cong-nghiep-cau-giay.jpg'}
        description={'Dịch vụ vệ sinh công nghiệp của chúng tôi bao gồm cả vệ sinh văn phòng, nhà xưởng, cửa hàng, khách sạn, nhà hàng và nhiều không gian công cộng khác. Chúng tôi sử dụng các thiết bị, công nghệ và chất tẩy rửa tiên tiến nhất để đảm bảo sự sạch sẽ và tiệm cận tối đa.'}
        primaryButtonText={'Liên hệ ngay'}
        buttonRounded={false}
        textOnLeft={false}
      />

      <MainFeature2 
        subheading={''}
        heading={'Đội ngũ kỹ sư chất lượng cao'}
        description={'Đội kỹ sư của chúng tôi được đào tạo chuyên sâu và có kiến thức chuyên môn rộng rãi trong các lĩnh vực khác nhau. Với nhiều năm kinh nghiệm làm việc trong ngành và việc liên tục nâng cao năng lực, chúng tôi tự tin khẳng định rằng đội ngũ của chúng tôi sẽ đáp ứng tối đa các yêu cầu của khách hàng.'}

      />

    {/* <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
    /> */}
    {/* <Blog /> */}
    <ContactUsForm 
      subheading={'Khi bạn có nhu cầu?'}
      heading={'Hãy liên hệ ngay cho chúng tôi'}
      description={'Đội ngũ tư vấn viên sẽ luôn hoạt động 24/7 để giải đáp và hỗ trợ bạn kịp thời.'}
      submitButtonText={'Gửi'}
      />
    <Footer />
  </AnimationRevealPage>
);
