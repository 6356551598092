import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "Blog",
  heading = <>We Love <span tw="text-primary-500">Writing.</span></>,
  description = "Some amazing blog posts that are written by even more amazing people.",

}) => {
  const blogPosts = [
    {
      imageSrc:
        "https://vatc.com.vn/vatc/wp-content/uploads/2021/05/hoc-dai-tu-dong-co-tren-o-to.jpg",
      author: "Adam Wathan",
      category: "SEO",
      title: "Sửa chữa và bảo trì động cơ",
      description: `Chúng tôi cung cấp dịch vụ tư vấn và hỗ trợ khách hàng 24/7.
        Đội ngũ chúng tôi luôn sẵn sàng lắng nghe và đưa ra những giải pháp sửa chữa & bảo trì động cơ chuyên nghiệp. Từ đó giúp khách hàng có thể yên tâm và vận hành máy móc một cách ổn định.`,
      url: "https://reddit.com"
    },
    {
      imageSrc:
        "https://thuany.vn/upload/images/phu-tung-xe-hoi-quang-ngai-1.png",
      author: "Owais Khan",
      category: "Advertising",
      title: "Cung cấp phụ tùng chính hãng",
      description: "chúng tôi tự hào cung cấp các phụ tùng chính hãng của các nhà sản xuất uy tín. Chúng tôi hiểu rằng sự tin tưởng và an tâm của khách hàng khi mua phụ tùng là điều cực kỳ quan trọng. Đó là lý do tại sao chúng tôi luôn đặt chất lượng lên hàng đầu và đảm bảo rằng tất cả các phụ tùng do chúng tôi cung cấp đều đáp ứng các tiêu chuẩn chất lượng cao nhất.",
      url: "https://timerse.com"
    },
    {
      imageSrc:
        "https://giatlacongnghe.110.vn/files/product/1197/15-09-2018/vesinhcongnghiepbinhduong_8BlVRATx.jpg",
      author: "Steve Schoger",
      category: "Social Media",
      title: "Vệ sinh công nghiệp",
      description: "Cung cấp các dịch vụ vệ sinh và bảo trì các tòa nhà, nhà máy, văn phòng, bệnh viện, trường học,… nhằm đảm bảo môi trường sạch sẽ, an toàn và ngăn ngừa các nguy cơ gây bệnh.",
      url: "https://timerse.com"
    }
  ];
  return (
    <Container id='dich-vu'>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Details>
                  {/* <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{post.author}</div>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{post.category}</div>
                    </Meta>
                  </MetaContainer> */}
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  {/* <Link href={post.url}>Read Post</Link> */}
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
